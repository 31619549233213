@mixin setFontSize($size) {
  font-size: $size;
  line-height: $size * 1.5;
}

@mixin setFontPrimary($size, $color) {
  font-family: var(--primary-regular);
  font-weight: 400;
  font-style: normal;
  font-size: $size;
  line-height: $size * 1.5;
  color: $color;
}
@mixin setFontPrimarySemiBold($size, $color) {
  font-family: var(--primary-semibold);
  font-weight: 600;
  font-style: normal;
  font-size: $size;
  line-height: $size * 1.5;
  color: $color;
}
@mixin setFontPrimaryBold($size, $color) {
  font-family: var(--primary-bold);
  font-weight: 700;
  font-style: normal;
  font-size: $size;
  line-height: $size * 1.5;
  color: $color;
}
// Secondary
@mixin setSecondaryRegular($size, $color) {
  font-family: var(--secondary-regular);
  font-weight: 400;
  font-style: normal;
  font-size: $size;
  line-height: $size * 1.5;
  color: $color;
}
@mixin setSecondarySemiBold($size, $color) {
  font-family: var(--secondary-semibold);
  font-weight: 600;
  font-style: normal;
  font-size: $size;
  line-height: $size * 1.5;
  color: $color;
}
@mixin setFontSecondaryBold($size, $color) {
  font-family: var(--secondary-bold);
  font-weight: 700;
  font-style: normal;
  font-size: $size;
  line-height: $size * 1.5;
  color: $color;
}
// Tertiary
@mixin setFontTertiaryBold($size, $color) {
  font-family: var(--tertiary-bold);
  font-weight: 700;
  font-style: normal;
  font-size: $size;
  line-height: $size * 1.5;
  color: $color;
}
