@import "./mixins/flex.scss";
@import "./mixins/chips.scss";
@import "./mixins/fonts.scss";
@import "./utilities/variables.scss";

.locationWrap {
  .locationContainer {
    .content {
      padding: 24px 16px;
      h2 {
        @include setSecondarySemiBold($fs-h6, var(--clr-secondary));
        text-align: center;
      }
      .regionList {
        margin-top: 18px;
        .regionWrap {
          .regionHeader {
            @include setSecondarySemiBold($fs-h6, var(--clr-secondary));
            display: inline-block;
          }
          .cityCard {
            background-color: #fff;
            border: 1px solid var(--border-lt);
            padding: 16px;
            margin-top: 16px;
            border-radius: 5px;
            .cityWrap {
              display: flex;
              margin: -10px;
              flex-wrap: wrap;

              .cityItem {
                width: calc(100% - 20px);
                margin: 10px;
                .cityHeader {
                  @include setSecondaryRegular($fs-h6, var(--clr-secondary));
                  text-decoration: underline;
                  cursor: pointer;
                  display: inline-block;
                }
                .cityList {
                  padding-left: 10px;
                  margin-top: 5px;
                  .cityListItem {
                    span {
                      @include setSecondaryRegular(
                        $fs-p4,
                        var(--clr-secondary)
                      );
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }
        .regionWrap:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .locationWrap {
    .locationContainer {
      .content {
        padding: 38px 32px;
        .regionList {
          .regionWrap {
            .cityCard {
              .cityWrap {
                .cityItem {
                  width: calc(25% - 20px);
                  margin: 10px;
                  text-align: center;
                  .cityList {
                    padding-left: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
