/* larger than heading font sizes */
$fs-giga: 70px;
$fs-mega: 60px;
$fs-kilo: 50px;

$fs-lg: 40px;

/* heading font sizes */
$fs-h1: 32px;
$fs-h2: 28px;
$fs-h3: 24px;
$fs-h4: 22px;
$fs-h5: 20px;
$fs-h6: 18px;

/* paragraph font sizes */
$fs-p4: 16px;
$fs-p3: 15px;
$fs-p2: 14px;
$fs-p1: 12px;

/* smaller than heading font sizes */
$fs-milli: 12px;
$fs-micro: 10px;
$fs-nano: 8px;
